import React, { useContext, useEffect, useState } from 'react';
import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getPersona, postRequest, putRequest, importarFile, getRequestHistory } from '../../utils/peticionesApi';
import { NewContext } from '../../utils/ContextProvider';
import { useParams } from 'react-router-dom';
import LoadingOverlay from '../../components/LoadingOverlay';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt } from "@fortawesome/free-solid-svg-icons";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '70vw',
    margin: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    width: '100%',
  },
  dateField: {
    margin: theme.spacing(1),
    width: '100%',
  },
  observationsField: {
    margin: theme.spacing(1),
    width: '100%',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: theme.spacing(3),
    marginTop: theme.spacing(4),
  },
  cancelButton: {
    width: 192,
    backgroundColor: '#FFFFFF',
    border: '1px solid #2A9AB6',
    color: '#2A9AB6',
    '&:hover': {
      backgroundColor: '#e6f7fc',
    },
  },
  submitButton: {
    width: 300,
    backgroundColor: '#8F3B8C',
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: '#7b336e',
    },
  },
}));

const AgregarSolicitudModal = ({
  reasons,
  rol,
  closeModalSolicitud,
  solicitud,
  handleSubmitSolicitud,
}) => {
  const classes = useStyles();
  const { getAlert, getId, selectedEmployee } = useContext(NewContext);
  const [request_since, setStartDate] = useState('');
  const [request_expiration, setEndDate] = useState('');
  const [amount, setAmount] = useState('');
  const [observations_request, setObservations] = useState('');
  const [id_reason, setSelectedMotivo] = useState('');
  const [selectedEmpleadoId, setSelectedEmpleadoId] = useState('');
  const [empleados, setEmpleados] = useState([]);
  const [nombre, setNombre] = useState('');
  const [isEditing, setIsEditing] = useState(true);
  const [active, setActive] = useState(rol === 'ROLE_USER' ? false : null);
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(rol === 'ROLE_USER' ? 'PENDIENTE' : null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [historialSolicitud, setHistorialSolicitud] = useState([]);

  useEffect(() => {
    if (rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH') {
      listarEmpleados();
    } else {
      setSelectedEmpleadoId(getId);
      if (selectedEmployee) {
        setNombre({
          name: selectedEmployee.person.name,
          lastname: selectedEmployee.person.lastname,
          id: selectedEmployee.id,
        });
      }
    }
    if (solicitud) {
      setStartDate(formatDateForInput(solicitud.request_since));
      setEndDate(formatDateForInput(solicitud.request_expiration));
      setObservations(solicitud.observations_request);
      setSelectedMotivo(solicitud.id_reason);
      setSelectedEmpleadoId(solicitud.uuid_employee);
      setNombre({
        name: solicitud.employee_first_name,
        lastname: solicitud.employee_last_name,
        id: solicitud.uuid_employee,
      });
      setActive(solicitud.active);
      setIsEditing(true);
      setAmount(solicitud.amount);
    } else {
      setStartDate('');
      setEndDate('');
      setObservations('');
      setSelectedMotivo('');
      setNombre('');
      setSelectedEmpleadoId(rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH' ? '' : getId);
      setIsEditing(true);
      if (rol === 'ROLE_USER') {
        setActive(false);
      }
    }
  }, [solicitud, selectedEmployee]);

  const formatDateForInput = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length !== 3) {
      return '';
    }
    const [year, month, day] = dateArray;
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  const handleMotivoChange = (event) => {
    setSelectedMotivo(event.target.value);
  };

  const handleEmpleadoChange = (event) => {
    const empleadoSeleccionado = empleados.find((empleado) => empleado.id === event.target.value);
    if (empleadoSeleccionado) {
      const { person, id } = empleadoSeleccionado;
      setNombre({ name: person.name, lastname: person.lastname, id });
      setSelectedEmpleadoId(event.target.value);
    }
  };

  const listarEmpleados = async () => {
    try {
      const response = await getPersona();
      setEmpleados(response.data.content);
    } catch (error) {
      console.error('Error al listar empleados:', error);
    }
  };

  const onSubmit = async () => {
    if (!selectedEmpleadoId) {
      getAlert({ msj: 'Debe seleccionar un empleado.', tipe: 'error' });
      return;
    }
    if (!request_since) {
      getAlert({ msj: 'Debe ingresar una fecha de inicio.', tipe: 'error' });
      return;
    }
    if (!id_reason) {
      getAlert({ msj: 'Debe seleccionar un motivo.', tipe: 'error' });
      return;
    }
    if (!status) {
      getAlert({ msj: 'Debe seleccionar un estado.', tipe: 'error' });
      return;
    }

    // Determinar a quién pertenece la solicitud
    const userId = solicitud ? solicitud.uuid_employee : getId;

    const formDataToSendSolicitud = {
      status,
      employee_first_name: nombre?.name || selectedEmployee?.person?.name || 'Nombre no definido',
      employee_last_name: nombre?.lastname || selectedEmployee?.person?.lastname || 'Apellido no definido',
      id_reason: id_reason,
      id_request: solicitud ? solicitud.id_request : 0, // En caso de nueva solicitud, será 0
      observations_request: observations_request,
      request_expiration: request_expiration || request_since,
      request_since: request_since,
      uuid_employee: nombre?.id || selectedEmpleadoId,
      amount: amount,
    };

    try {
      setIsLoading(true);
      let response;

      if (!solicitud) {
        // **Crear nueva solicitud**
        response = await postRequest(formDataToSendSolicitud, getId);
      } else {
        // **Actualizar solicitud existente**
        response = await putRequest(formDataToSendSolicitud, getId);
      }

      if (response.data?.success === false) {
        getAlert({ msj: response.data.message, tipe: 'error' });
        return;
      }

      // **Obtener el ID de la nueva solicitud creada**
      const solicitudId = response.data.id;
      console.log("solicitudId asdsadd", solicitudId);

      if (uploadedFile) {
        // **Esperar 1 segundo antes de intentar importar el archivo**
        setTimeout(async () => {
          try {
            await importarFile(selectedEmpleadoId, uploadedFile, '');
            getAlert({ msj: "📂 Archivo enviado exitosamente.", tipe: "succes" });
          } catch (error) {
            console.error("❌ Error al enviar el archivo:", error);
            getAlert({ msj: "⚠️ Error al enviar el archivo adjunto.", tipe: "error" });
          }
        }, 1000); // 🔴 Espera 1 segundo para asegurar que el backend procese la nueva solicitud
      }

      getAlert({ msj: solicitud ? '✍️ Solicitud editada con éxito.' : '✅ Solicitud agregada con éxito.', tipe: 'succes' });

      handleSubmitSolicitud(formDataToSendSolicitud);
      closeModalSolicitud();
    } catch (error) {
      console.error('❌ Error al enviar la solicitud:', error);
      getAlert({
        msj: error.response?.data?.message || 'Error al procesar la solicitud.',
        tipe: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileUpload = (event) => {
    const selectedFile = event.target.files[0]; // Archivo seleccionado
    if (!selectedFile) {
      console.error("⚠️ No has seleccionado ningún archivo.");
      return;
    }
    setUploadedFile(selectedFile); // Guardar archivo en el estado sin enviarlo aún
    getAlert({ msj: "Archivo adjuntado exitosamente. Recuerda confirmar la solicitud para enviarlo.", tipe: "info" });
  };

  useEffect(() => {
    if (solicitud) {
      obtenerHistorialSolicitud();
    }
  }, [solicitud]);

  const obtenerHistorialSolicitud = async () => {
    try {
      const response = await getRequestHistory(solicitud.uuid_employee, solicitud.id_request);
      if (response?.data) {
        setHistorialSolicitud(response.data);
      } 
    } catch (error) {
      console.error("Error al obtener historial:", error);
    }
  };

  const formatDateLocalTime = (dateArray) => {
    if (!Array.isArray(dateArray) || dateArray.length < 6) {
      return "Fecha inválida";
    }

    const [year, month, day, hours, minutes, seconds] = dateArray;

    const formattedDate = `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

    return `${formattedDate} ${formattedTime}`;
  };

  return (
    <>
      <LoadingOverlay isLoading={isLoading} />
      <div className={classes.root}>
        <Grid container spacing={2}>
          {/* Empleado */}
          <Grid item xs={12} sm={6}>
            {rol === 'ROLE_ADMIN' || rol === 'ROLE_RRHH' ? (
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id="empleado-label">Empleados</InputLabel>
                <Select
                  labelId="empleado-label"
                  id="empleados"
                  value={selectedEmpleadoId}
                  onChange={handleEmpleadoChange}
                  disabled={!!solicitud}
                  label="Empleados"
                >
                  {empleados &&
                    empleados.map((empleado) => (
                      <MenuItem key={empleado.id} value={empleado.id}>
                        {empleado.person.name} {empleado.person.lastname}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            ) : (
              <div>
                <InputLabel id="solicitante-label">Solicitante</InputLabel>
                <p>{selectedEmployee?.person.name} {selectedEmployee?.person.lastname}</p>
              </div>
            )}
          </Grid>

          {/* Motivo */}
          <Grid item xs={12} sm={6}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="motivo-label">Motivos</InputLabel>
              <Select
                labelId="motivo-label"
                id="motivo"
                value={id_reason}
                onChange={handleMotivoChange}
                disabled={!isEditing}
                label="Motivos"
              >
                {reasons &&
                  reasons.map((reason) => (
                    <MenuItem key={reason.id} value={reason.id}>
                      {reason.reason}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid container spacing={2}>
            {/* IZQUIERDA: Fecha de inicio y Monto a solicitar */}
            <Grid item xs={12} sm={6}>
              <Grid container spacing={2}>
                {/* Fecha de inicio */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    id="startDate"
                    label="Fecha de inicio"
                    type="date"
                    value={request_since}
                    onChange={(event) => setStartDate(event.target.value)}
                    className={classes.dateField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    disabled={!isEditing}
                    variant="outlined"
                  />
                </Grid>
                {/* Monto a solicitar o Fecha de Fin */}
                <Grid item xs={12} sm={6}>
                  {["adelanto de sueldo", "Adelanto de sueldo", "Descuentos"].includes(
                    reasons.find((r) => r.id === id_reason)?.reason || ""
                  ) ? (
                    <TextField
                      id="monto"
                      label={
                        reasons.find((r) => r.id === id_reason)?.reason === "Descuentos"
                          ? "Monto a descontar"
                          : "Monto a solicitar"
                      }
                      type="number"
                      value={amount}
                      onChange={(event) => setAmount(event.target.value)}
                      className={classes.dateField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={!isEditing}
                      variant="outlined"
                      InputProps={{
                        startAdornment: <span style={{ marginRight: '8px', fontWeight: 'bold' }}>$</span>
                      }}
                    />
                  ) : (
                    <TextField
                      id="finishDate"
                      label="Fecha de fin"
                      type="date"
                      value={request_expiration}
                      onChange={(event) => setEndDate(event.target.value)}
                      className={classes.dateField}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      disabled={!isEditing}
                      variant="outlined"
                    />
                  )}
                </Grid>

                {/* Estado (debajo de Fecha de Inicio y Monto a Solicitar) */}
                <Grid item xs={12}>
                  <FormControl variant="outlined" className={classes.formControl} fullWidth>
                    <InputLabel id="estado-label">Estado</InputLabel>
                    <Select
                      labelId="estado-label"
                      id="estado"
                      value={status}
                      onChange={(event) => setStatus(event.target.value)}
                      disabled={rol === 'ROLE_USER'}
                      label="Estado"
                    >
                      <MenuItem value="APROBADA">APROBADA</MenuItem>
                      <MenuItem value="RECHAZADA">RECHAZADA</MenuItem>
                      <MenuItem value="OBSERVADA">OBSERVADA</MenuItem>
                      <MenuItem value="PENDIENTE">PENDIENTE</MenuItem>
                    </Select>
                  </FormControl>

                  {/* Observaciones */}
                  <Grid item xs={12} sm={12}>
                    <TextField
                      id="observations"
                      label="Observaciones"
                      multiline
                      minRows={4}
                      value={observations_request}
                      onChange={(event) => setObservations(event.target.value)}
                      className={classes.observationsField}
                      disabled={!isEditing}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* DERECHA: Historial de Cambios ocupando todo el lado derecho */}
            {solicitud && (
              <Grid item xs={12} sm={6}>
                <div style={{
                  border: '1px solid #ccc',
                  padding: '16px',
                  borderRadius: '8px',
                  maxHeight: '240px',
                  overflow: 'hidden', // Para que el contenedor no desborde
                  marginTop: '8px',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <h3 style={{ textAlign: 'center', marginBottom: '10px' }}>Historial de Cambios</h3>
                  {historialSolicitud.length > 0 ? (
                    <div style={{ overflowY: 'auto', maxHeight: '320px' }}> {/* Scroll en la tabla */}
                      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                        <thead>
                          <tr>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px', background: '#f8f8f8', position: 'sticky', top: '0' }}>Fecha</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px', background: '#f8f8f8', position: 'sticky', top: '0' }}>Modificado por</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px', background: '#f8f8f8', position: 'sticky', top: '0' }}>Observaciones</th>
                            <th style={{ borderBottom: '1px solid #ddd', padding: '8px', background: '#f8f8f8', position: 'sticky', top: '0' }}>Estado</th>
                          </tr>
                        </thead>
                        <tbody>
                          {historialSolicitud.map((entry, index) => (
                            <tr key={index}>
                              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                {formatDateLocalTime(entry.changeDate)}
                              </td>
                              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                {entry.changedByFirstName} {entry.changedByLastName}
                              </td>
                              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                {entry.observations}
                              </td>
                              <td style={{ borderBottom: '1px solid #ddd', padding: '8px' }}>
                                <span
                                  className={`itemActive ${entry.status === "APROBADA"
                                    ? ""
                                    : entry.status === "RECHAZADA"
                                      ? "itemInactive"
                                      : entry.status === "OBSERVADA"
                                        ? "itemObserved"
                                        : "itemPending"
                                    }`}
                                >
                                  {entry.status === "APROBADA"
                                    ? "Aprobado"
                                    : entry.status === "RECHAZADA"
                                      ? "Rechazado"
                                      : entry.status === "OBSERVADA"
                                        ? "Observado"
                                        : "Pendiente"}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p style={{ textAlign: 'center' }}>No hay historial disponible.</p>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
          {/* Contenedor del Importador de Archivos */}
          <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: '1px dashed #ccc',
            padding: '16px',
            borderRadius: '8px',
            width: '100%',
            maxWidth: '500px'
          }}>
            <p style={{ marginBottom: '8px', fontWeight: 'bold' }}>Importar archivo</p>
            <input
              type="file"
              id="dni-upload"
              style={{ display: 'none' }}
              onChange={(event) => handleFileUpload(event)}
            />
            <label htmlFor="dni-upload" style={{ cursor: 'pointer', textAlign: 'center' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <FontAwesomeIcon icon={faCloudUploadAlt} size="2x" color="#2A7FEC" />
                <p style={{ marginTop: '8px', color: '#666' }}>Sube archivos en caso de ser necesario</p>
              </div>
            </label>
            {uploadedFile && (
              <div style={{
                marginTop: '16px',
                padding: '8px 16px',
                backgroundColor: '#f7f7f7',
                borderRadius: '4px',
                textAlign: 'center',
                fontSize: '14px',
                color: '#333',
              }}>
                Archivo cargado: {uploadedFile.name}
              </div>
            )}
          </div>

          {/* Contenedor de Botones */}
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '16px'
          }}>
            <Button className={classes.cancelButton} onClick={closeModalSolicitud}>
              Cancelar
            </Button>
            <Button className={classes.submitButton} onClick={onSubmit}>
              {solicitud ? "Guardar cambios" : "Agregar solicitud"}
            </Button>
          </div>
        </Grid>

      </div>
    </>
  );
};

export default AgregarSolicitudModal;
