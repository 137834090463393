import React, { useEffect, useState, useContext } from 'react';
import Select from 'react-select';
import { getAllDepartment } from '../utils/peticionesApi';
import { NewContext } from "../utils/ContextProvider";

const SelectDepartamentos = ({ selectedDepartments, setSelectedDepartments }) => {
    const [departamentos, setDepartamentos] = useState([]);
    const { getCompany } = useContext(NewContext);

    useEffect(() => {
        const findDepartamentos = async () => {
            try {
                const res = await getAllDepartment(getCompany);
                if (res?.data?.data) {
                    const newDepartamentos = res.data.data.map(departamento => ({
                        value: departamento.id, // Mantenerlo como número
                        label: departamento.dept_name
                    }));
                    setDepartamentos(newDepartamentos);
                } else {
                    console.error('Estructura inesperada del backend:', res);
                }
            } catch (error) {
                console.error('Error al obtener departamentos:', error);
            }
        };
        findDepartamentos();
    }, [getCompany]);

    const handleChange = (selectedOptions) => {
        const selectedIds = selectedOptions ? selectedOptions.map(option => option.value) : [];
        setSelectedDepartments(selectedIds); // Guarda los valores seleccionados en el estado global
    };

    return (
        <Select
            options={departamentos}
            isMulti // Permite seleccionar múltiples departamentos
            value={departamentos.filter(dep => selectedDepartments.includes(dep.value))}
            onChange={handleChange}
            placeholder="Selecciona uno o más departamentos"
        />
    );
};

export default SelectDepartamentos;