import React, { useState } from 'react';
import '../css/OptionComponent.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faXmark, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons'
import FilterModal from '../components/FilterModal';

const OptionComponentSolicitudes = ({ value, onChange, agregarFunction, modalView, onApplyFilters }) => {
  const [modalFilters, setModalFilters] = useState(false);
  return (
    <div className='contenedorOptions'>
      <div className="inputSearchContainer">
        <FontAwesomeIcon icon={faSearch} className="search-icon" />
        <input
          className='inputComponent'
          type='text'
          placeholder='Buscar por nombre, apellido o fecha'
          value={value}
          onChange={onChange}
        />
      </div>

      <button className="filter-button" onClick={() => setModalFilters(true)}>
        <FontAwesomeIcon icon={faFilter} className="filter-icon" />
        <span className="filter-text">Filtros</span>
      </button>

      <div>
        <button className='button-icons-options' onClick={agregarFunction}>
          {
            !modalView ?
              <FontAwesomeIcon icon={faPlus} className='icon-options' />
              :
              <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
          }
        </button>
      </div>
    </div>
  )
}

export default OptionComponentSolicitudes;
