import { React, useContext, useEffect, useState } from "react";
import '../css/Home.css';
import '../css/Asistencia.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import CustomPagination from '../components/CustomPagination';
import { NewContext } from "../utils/ContextProvider";
import OptionComponent from '../components/OptionComponentReporteEventos';
import { getAsistencia, getExportWithDetail, getExportWithOutDetail, getListPersona } from "../utils/peticionesApi";
import Button from "../components/Button";
import SelectEmpleados from "../components/SelectEmpleados";
import MostrarAsistenciaModal from "../forms/Asistencia/MostrarAsistenciaModal.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreenButton, faCalculator } from "@fortawesome/free-solid-svg-icons";
import SelectDepartamentos from "../components/SelectDepartamentos";

const Asistencia = () => {
  const { getCompany, getAlert, getDepartment } = useContext(NewContext)
  const [empleados, setEmpleados] = useState([]);
  const [personas, setPersonas] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [showPopup, setShowPopup] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedStartDate, setSelectedStartDate] = useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [dateExport, setDateExport] = useState({
    exportDesde: null,
    exportHasta: null
  })
  const [uuid, setUuid] = useState(null)
  const [modalView, setModalView] = useState(false);
  const [modalViewExport, setModalViewExport] = useState(false);
  const [detalle, setDetalle] = useState(true)
  const [detail, setDetail] = useState(false)
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const dateStringInicio = selectedStartDate.toDateString();
  const formattedDateInicio = formatDate(dateStringInicio);
  const dateStringFin = selectedEndDate.toDateString();
  const formattedDateFin = formatDate(dateStringFin);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const employeesPerPage = 20;
  const [showModal, setShowModal] = useState(false);
  const [idDepartamento, setIdDepartamento] = useState(getDepartment);
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedObjectives, setSelectedObjectives] = useState([]);

  function formatDate(dateString) {
    const months = {
      Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06',
      Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12'
    };
    const [nameDay, monthStr, day, year] = dateString.split(' ');
    const month = months[monthStr];

    return `${day}/${month}/${year}`;
  }

  const handleDateChange = newDates => {
    if (newDates.length === 1) {
      setSelectedStartDate(newDates[0]);
      setSelectedEndDate(null);
    } else if (newDates.length === 2) {
      setSelectedStartDate(newDates[0]);
      setSelectedEndDate(newDates[1]);
      setModalView(!modalView)
    }
  };

  const handleExportChange = e => {
    const [año, mes, dia] = e.target.value.split('-')
    setDateExport({ ...dateExport, [e.target.name]: `${dia}/${mes}/${año}` })
  }

  const submitExport = async () => {
    if (!dateExport.exportDesde || !dateExport.exportHasta) {
      return getAlert({ msj: `No se ingresó la fecha.`, tipe: 'error' });
    }

    const departmentIds = selectedDepartments.length > 0
      ? [...new Set(selectedDepartments)].filter(id => id).join(',')
      : '0';

    const zktecoAvailable = selectedDepartments.length > 0;

    try {
      const peticion = detail
        ? getExportWithDetail(-1, dateExport.exportDesde, dateExport.exportHasta, uuid, departmentIds, zktecoAvailable)
        : getExportWithOutDetail(-1, dateExport.exportDesde, dateExport.exportHasta, uuid, departmentIds, zktecoAvailable);

      const res = await peticion;
      const blob = new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'asistencias.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.log(error);
      getAlert({ msj: "Error al exportar los datos", tipe: "error" });
    }
  };

  const handlePopupOpen = (empleado) => {
    setSelectedEmployee(empleado);
    //setPersonas(empleado.uuid)
    setShowPopup(true);
  };

  function toZktecoFormat(dateString) {
    const [day, month, year] = dateString.split('/');
    return `${year}-${month}-${day}`;
  }

  const [allEmpleados, setAllEmpleados] = useState([]);
  const [filteredEmpleados, setFilteredEmpleados] = useState([]);

  const listarEmpleados = async () => {
    const formattedDateInicioZkteco = toZktecoFormat(formattedDateInicio);
    const formattedDateFinZkteco = toZktecoFormat(formattedDateFin);

    try {
      const companyParam = -1;
      const departmentIds = selectedDepartments.length > 0
        ? selectedDepartments.join(',')
        : (getDepartment?.length > 0 ? getDepartment.join(',') : '0');
      const hasClock = departmentIds !== '0';

      const response = await getAsistencia(
        formattedDateInicio,
        formattedDateFin,
        0,
        1000,
        companyParam,
        departmentIds,
        formattedDateInicioZkteco,
        formattedDateFinZkteco,
        hasClock
      );

      setAllEmpleados(response.data.content);
      setFilteredEmpleados(response.data.content);
      setTotalPages(Math.ceil(response.data.content.length / employeesPerPage));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleApplyFilters(selectedObjectives, selectedCity);
  }, [allEmpleados]);


  useEffect(() => {
    listarEmpleados();
  }, [formattedDateInicio, formattedDateFin, selectedDepartments]);

  useEffect(() => {
    const filtered = allEmpleados.filter((empleado) => {
      const fullName = `${empleado.name} ${empleado.lastname}`;
      return fullName.toLowerCase().includes(searchTerm.toLowerCase());
    });

    setFilteredEmpleados(filtered);
    setTotalPages(Math.ceil(filtered.length / employeesPerPage));
    setCurrentPage(0);
  }, [searchTerm, allEmpleados]);

  const paginatedEmpleados = filteredEmpleados.slice(
    currentPage * employeesPerPage,
    (currentPage + 1) * employeesPerPage
  );

  const handleRowClick = async (empleado) => {
    setSelectedEmployee(empleado);

    if (!personas || personas.uuid !== empleado.uuid) {
      try {
        const response = await getListPersona(empleado.uuid);
        setPersonas(response.data);
      } catch (error) {
        console.log(error);
      }
    }

    setShowModal(true);
  };

  useEffect(() => {
    let filtered = allEmpleados;

    if (selectedObjectives.length > 0) {
      filtered = filtered.filter(emp =>
        selectedObjectives.includes(emp.nameObjective)
      );
    }

    if (selectedCity.trim() !== '') {
      filtered = filtered.filter(emp =>
        emp.cityObjective &&
        emp.cityObjective.toLowerCase().includes(selectedCity.toLowerCase())
      );
    }

    setFilteredEmpleados(filtered);
    setTotalPages(Math.ceil(filtered.length / employeesPerPage));
    setCurrentPage(0);
  }, [selectedObjectives, selectedCity, allEmpleados]);

  const handleApplyFilters = (objectives, city, type) => {
    console.log("🔹 Filtros recibidos - Objectives:", objectives);
    console.log("🔹 Filtros recibidos - City:", city);
    console.log("🔹 Filtros recibidos - Type:", type || "No seleccionado");

    let filtered = allEmpleados;

    if (objectives.length > 0) {
      filtered = filtered.filter(emp => objectives.includes(emp.nameObjective));
    }

    if (city.trim() !== '') {
      filtered = filtered.filter(emp =>
        emp.cityObjective &&
        emp.cityObjective.toLowerCase().includes(city.toLowerCase())
      );
    }

    if (type && (type === "I" || type === "E")) {
      filtered = filtered.filter(emp => {
        return emp.type === type;
      });
    } else {
      console.log("⚠️ Tipo de ingreso no seleccionado o inválido.");
    }

    setFilteredEmpleados(filtered);
    setTotalPages(Math.ceil(filtered.length / employeesPerPage));
    setCurrentPage(0);
  };

  return (
    <>
      <div className='asistcontainer'>
        <p className="titulo">Asistencias</p>
        <OptionComponent
          type="text"
          placeHolder="Buscar por nombre, apellido o DNI"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          modalView={modalView}
          setModalView={setModalView}
          modalViewExport={modalViewExport}
          setModalViewExport={setModalViewExport}
          onApplyFilters={handleApplyFilters}
        />
        {
          modalView ?
            <div className="modalCalendar">
              <Calendar onChange={handleDateChange} value={selectedEndDate ? [selectedStartDate, selectedEndDate] : selectedStartDate} selectRange={true} />
            </div>
            :
            null
        }
        {
          modalViewExport ?
            <div className="modalExport">
              <div className="contenedorBotonesFiltroEmpleado">
                <button onClick={() => setDetalle(!detalle)} className={`${!detalle ? 'buttonFiltroAzul' : 'buttonFiltroGris'}`}>Por empleado</button>
                <button onClick={() => setDetalle(!detalle)} className={`${detalle ? 'buttonFiltroAzul' : 'buttonFiltroGris'}`}>Todos los empleados</button>
              </div>
              {!detalle ? (
                <div className="inputSelectEmpleado">
                  <SelectEmpleados setUuid={setUuid} />
                </div>
              ) : null}
              {(
                <div className="inputSelectEmpleado">
                  <SelectDepartamentos
                    selectedDepartments={selectedDepartments}
                    setSelectedDepartments={setSelectedDepartments}
                    placeholder="Selecciona uno o más departamentos"
                  />
                </div>
              )}
              <div className="contenedorDesadeHasta">
                <div>
                  <p>Desde</p>
                  <input className="inputDate" type="date" name='exportDesde' onChange={handleExportChange} />
                </div>
                <div>
                  <p>Hasta</p>
                  <input className="inputDate" name='exportHasta' onChange={handleExportChange} type="date" />
                </div>
              </div>
              <div className="contenedorRarioButtons">
                <div className="contenedorRadio">
                  <input type="radio" value={true} name="detalle" checked={detail === true} onChange={e => setDetail(e.target.value === 'true')} />
                  <p>Con detalle</p>
                </div>
                <div className="contenedorRadio">
                  <input type="radio" value={false} name='detalle' checked={detail === false} onChange={e => setDetail(e.target.value === 'true')} />
                  <p>Sin detalle</p>
                </div>
              </div>
              <Button onClick={submitExport} msj='Exportar Excel' tipe='violeta' />
            </div>
            : null
        }
        <div className="data-table">
          <table>
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Apellido</th>
                <th scope="col">Fecha</th>
                <th scope="col">Hora</th>
                <th scope="col">Lugar y Puesto</th>
                <th scope="col">Ciudad</th>
                <th scope="col">Evento</th>
                <th scope="col">Fichaje</th>
              </tr>
            </thead>
            <tbody>
              {paginatedEmpleados.length === 0 ? (
                <tr>
                  <td colSpan="7">No posee registros en el día de la fecha.</td>
                </tr>
              ) : (
                paginatedEmpleados.map((empleado, index) => (
                  <tr key={index} onClick={() => handleRowClick(empleado)}>
                    <td className="align-middle">{empleado.name}</td>
                    <td className="align-middle">{empleado.lastname}</td>
                    <td className="align-middle">{empleado.date}</td>
                    <td className="align-middle">{empleado.registry}</td>
                    <td className="align-middle" style={{ display: 'flex', flexDirection: 'column' }}>
                      {empleado.nameObjective}
                      <span style={{ color: '#6C757D', fontSize: '14px' }}>{empleado.namePlace}</span>
                    </td>
                    <td className="align-middle">{empleado.cityObjective}</td>
                    <td className="align-middle">
                      {empleado.type === 'I' ? (
                        <span className="spanIngreso">Ingreso</span>
                      ) : empleado.type === 'E' ? (
                        <span className="spanEgreso">Egreso</span>
                      ) : (
                        <span className="spanAusente">Ausente</span>
                      )}
                    </td>
                    <td className="align-middle">
                      {empleado.appRegistry ? (
                        <FontAwesomeIcon
                          icon={faMobileScreenButton}
                          style={{ color: 'rgb(42, 154, 182)' }}
                          title="Registrado por App"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCalculator}
                          style={{ color: 'rgb(42, 154, 182)' }}
                          title="Registrado por Huellero"
                        />
                      )}
                    </td>
                  </tr>
                ))
              )}
            </tbody>

          </table>
        </div>
      </div>
      <div className="d-flex justify-content-center mt-4">
        <CustomPagination
          currentPage={currentPage}
          totalPages={totalPages}
          setCurrentPage={setCurrentPage}
        />
      </div>
      {showModal && (
        <MostrarAsistenciaModal
          setShowModal={setShowModal}
          asistencia={selectedEmployee}
        />
      )}
    </>
  );
};

export default Asistencia;