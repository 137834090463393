import React, { useState } from 'react';
import '../css/OptionComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faDownload, faXmark, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import FilterModal from '../components/FilterModal';

const OptionComponentReporteEventos = ({
    type, placeHolder, value, onChange,
    setModalView, modalView,
    setModalViewExport, modalViewExport,
    onApplyFilters
}) => {

    const [modalFilters, setModalFilters] = useState(false);

    return (
        <div className='contenedorOptions'>
            <div className="inputSearchContainer">
                <FontAwesomeIcon icon={faSearch} className="search-icon" />
                <input
                    className='inputComponent'
                    type={type}
                    placeholder={placeHolder}
                    value={value}
                    onChange={onChange}
                />
            </div>

            <button className="filter-button" onClick={() => setModalFilters(true)}>
                <FontAwesomeIcon icon={faFilter} className="filter-icon" />
                <span className="filter-text">Filtros</span>
            </button>

            <div>

                <button className='button-icons-options' onClick={() => { setModalView(!modalView); setModalViewExport(false) }}>
                    {
                        !modalView ?
                            <FontAwesomeIcon icon={faCalendar} className='icon-options' />
                            :
                            <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
                    }
                </button>

                <button className='button-icons-options' onClick={() => { setModalViewExport(!modalViewExport); setModalView(false) }}>
                    {
                        !modalViewExport ?
                            <FontAwesomeIcon icon={faDownload} className='icon-options' />
                            :
                            <FontAwesomeIcon icon={faXmark} className='icon-options-close' />
                    }
                </button>

            </div>

            <FilterModal
                isOpen={modalFilters}
                onClose={() => setModalFilters(false)}
                onApplyFilters={(objectives, city, type) => {
                    setModalFilters(false);
                    onApplyFilters(objectives, city, type);
                }}
            />

        </div>
    )
}

export default OptionComponentReporteEventos;

