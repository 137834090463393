import React, { useState } from 'react';
import '../css/OptionComponent.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFilter, faXmark, faUpload, faPlus } from '@fortawesome/free-solid-svg-icons';

const OptionComponenteEmpleado = ({
  value,
  onChange,
  setModalView,
  agregarFunction,
  modalView,
  setModalViewExport,
  modalViewExport,
  upload,
  onApplyFilters,
}) => {

  const [modalFilters, setModalFilters] = useState(false);

  return (
    <div className="contenedorOptions">
      <div className="inputSearchContainer">
        <FontAwesomeIcon icon={faSearch} className="search-icon" />

        <input
          className="inputComponent"
          type="text"
          placeholder="Buscar por nombre, apellido o DNI"
          value={value}
          onChange={onChange}
        />
      </div>

      <button className="filter-button" onClick={() => setModalFilters(true)}>
        <FontAwesomeIcon icon={faFilter} className="filter-icon" />
        <span className="filter-text">Filtros</span>
      </button>

      <div>
        <button className="button-icons-options" onClick={agregarFunction}>
          <FontAwesomeIcon icon={!modalView ? faPlus : faXmark} className="icon-options" />
        </button>
        <button
          className="button-icons-options"
          onClick={upload}
        >
          <FontAwesomeIcon icon={!modalViewExport ? faUpload : faXmark} className="icon-options" />
        </button>
      </div>
    </div>
  );
};

export default OptionComponenteEmpleado;
