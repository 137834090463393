import { React, useContext, useState } from "react";
import '../css/FilterModal.css';
import SelectDepartamentos from '../components/SelectDepartamentos';
import { NewContext } from "../utils/ContextProvider";

const FilterModal = ({ isOpen, onClose, onApplyFilters }) => {
  const [selectedIds, setSelectedIds] = useState([]);
  const [selectedCity, setSelectedCity] = useState('');
  const [selectedType, setSelectedType] = useState('');

  const { getDepartment } = useContext(NewContext);

  if (!isOpen) return null;

  const convertIdsToNames = (ids) => {
    return ids
      .map((id) => {
        const found = getDepartment.find((dept) => dept.id === id);
        return found ? found.name : null;
      })
      .filter(Boolean);
  };

  const handleApply = () => {
    const objectives = convertIdsToNames(selectedIds);
    onApplyFilters(objectives, selectedCity, selectedType);
    onClose();
  };


  return (
    <div className="filter-modal-overlay">
      <div className="filter-modal">
        <h3>Filtrar Asistencias</h3>

        <label>Departamento </label>
        <SelectDepartamentos
          selectedDepartments={selectedIds}
          setSelectedDepartments={setSelectedIds}
        />

        <label>Ciudad:</label>
        <input
          type="text"
          placeholder="Ingrese ciudad"
          value={selectedCity}
          onChange={(e) => setSelectedCity(e.target.value)}
        />

        <label>Tipo de Ingreso:</label>
        <select
          value={selectedType}
          onChange={(e) => setSelectedType(e.target.value)}
        >
          <option value="">Todos</option>
          <option value="I">Ingreso</option>
          <option value="E">Egreso</option>
        </select>

        <div className="filter-modal-buttons">
          <button className="cancel-button" onClick={onClose}>Cancelar</button>
          <button className="apply-button" onClick={handleApply}>
            Aplicar Filtros
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;
