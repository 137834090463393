import React, { useContext, useEffect, useState } from "react";
import "../css/Objetivos.css";
import CustomPagination from "../components/CustomPagination";
import { NewContext } from "../utils/ContextProvider";
import AgregarObjetivoModal from '../forms/Objetivo/AgregarObjetivoModal';
import { getObjetivos, deleteObjetivo } from "../utils/peticionesApi";
import { useNavigate } from "react-router-dom";
import OptionComponentObjetivos from "../components/OptionComponentObjetivos";
import LoadingOverlay from "../components/LoadingOverlay";
import Button from "../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft, faTrash, faPenToSquare, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const Objetivos = () => {
  const { getToken, getAlert } = useContext(NewContext);
  const [objetivos, setObjetivos] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showPopup] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [menuVisible, setMenuVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalDeleteObjetivo, setModalDeleteObjetivo] = useState(false);
  const [selectedRequestId, setSelectedRequestId] = useState(null);
  const navigate = useNavigate();

  const objetivosPerPage = 20;

  const confirmarEliminarObjetivo = (id) => {
    setSelectedRequestId(id);
    setModalDeleteObjetivo(true);
  };

  const listarObjetivos = async () => {
    try {
      setIsLoading(true);
      const response = await getObjetivos();
      setObjetivos(response.data.content);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const eliminarEmpresa = async () => {
    try {
      const response = await deleteObjetivo(selectedRequestId);
      if (response.status === 200) {
        getAlert({ msj: "Empresa eliminada con éxito.", tipe: "succes" });
        listarObjetivos();
      } else {
        getAlert({ msj: response.data.message });
      }
      setModalDeleteObjetivo(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!getToken) return;
    if (searchTerm !== "") {
      listarObjetivos(0, 3000);
    } else {
      // Si no hay término de búsqueda, cargar todas los objetivos paginadas
      listarObjetivos(objetivosPerPage);
    }
  }, [searchTerm, showPopup, menuVisible, getToken]);

  const filteredobjetivos =
    objetivos?.filter((objetivo) => {
      const fullDireccion =
        objetivo.address?.street + " " + objetivo.address?.number;
      return (
        objetivo.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        objetivo.city.name.includes(searchTerm) ||
        fullDireccion.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }) || [];


  const handleApplyFilters = () => {
    alert("En construccion");
  }

  const TableRow = ({ objetivo, index }) => (
    <tr
      className="itemTablaObjetivo"
      key={index}
      onClick={() => navigate(`/objetivo/${objetivo.id}`)}
    >
      <td>{objetivo.name}</td>
      <td>
        {objetivo.address.street} {objetivo.address.number}
      </td>
      <td>{objetivo.city.name}</td>
      <td style={{ textAlign: "center" }}>
        <span className={objetivo.active ? "itemActive" : "itemInactive"}>
          {objetivo.active ? "Activo" : "Inactivo"}
        </span>
      </td>
      <td className="contenedor-item">
        <FontAwesomeIcon
          icon={faPenToSquare}
          className="icon-edit"
          onClick={() => navigate(`/objetivo/edit/${objetivo.id}`)}
        />
        <FontAwesomeIcon
          icon={faTrash}
          className="icon-salida"
          onClick={(e) => {
            e.stopPropagation();
            confirmarEliminarObjetivo(objetivo.id);
          }}
        />
      </td>
    </tr>
  );

  if (menuVisible) {
    return (
      <AgregarObjetivoModal
        setMenuVisible={setMenuVisible}
        menuVisible={menuVisible}
        onClose={() => {
          setMenuVisible(false);
          listarObjetivos();
        }}
      />
    );
  }

  return (
    <div className="contenedorObjetivo">
      <LoadingOverlay isLoading={isLoading} />
      <p className="titulo">Empresas</p>

      <OptionComponentObjetivos
        type="text"
        placeHolder="Buscar por nombre o dirección"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        setMenuVisible={setMenuVisible}
        menuVisible={menuVisible}
        onApplyFilters={handleApplyFilters}
      />

      <div className="data-table">
        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Dirección</th>
              <th>Ciudad</th>
              <th>Estado</th>
              <th style={{ width: "17%" }}>Acciones</th>

            </tr>
          </thead>
          <tbody>
            {filteredobjetivos.length > 0 ? (
              filteredobjetivos.map((objetivo) => (
                <TableRow key={objetivo.id} objetivo={objetivo} />
              ))
            ) : (
              <tr>
                <td colSpan="5">No se encontraron resultados.</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="d-flex justify-content-center mt-4">
          <CustomPagination currentPage={0} totalPages={totalPages} />
        </div>
      </div>

      {modalDeleteObjetivo && (
        <div className="modalDelete">
          <FontAwesomeIcon icon={faTriangleExclamation} className="iconoDelete" />
          <p>¿Estás seguro de eliminar esta empresa?</p>
          <div className="contenedorButonsDelete">
            <Button
              tipe="sinBg"
              msj="Cancelar"
              onClick={() => setModalDeleteObjetivo(false)}
            />
            <Button msj="Confirmar" onClick={eliminarEmpresa} />
          </div>
        </div>
      )}
    </div>
  );
};

export default Objetivos;